import React from 'react'
import Seo from '../components/seo'
import { ReactComponent as Helm } from '../images/helm-logo.svg'
import { ReactComponent as Operator } from '../images/operator-logo.svg'
import { ReactComponent as StackRox } from '../images/stackrox-icon.svg'
import {
  Activity,
  Database,
  FileText,
  Home,
  Minimize2,
  Settings,
  Terminal,
  TrendingUp,
} from 'react-feather'

export default function DocsPage() {
  const headingStyle = 'font-headings text-3xl lg:text-5xl text-base-800 mb-2 lg:mb-4'
  const pStyle = 'max-w-[65ch] text-center text-lg lg:text-xl text-base-600 mb-4'
  const ledeStyle = 'max-w-[60ch] text-center text-xl lg:text-2xl text-base-600 mb-4'
  const aStyle =
    'underline text-primary-700 visited:text-primary-900 focus:ring-2 focus:ring-primary-600 hover:text-warning-700'

  const IconBox = ({ ...props }) => {
    const { title, url, icon, round } = props

    return (
      <a
        target="_blank"
        className={`${
          round && `w-64`
        } border-2 bg-base-100 hover:border-base-500 hover:bg-base-200 focus:bg-base-200 focus:outline-none focus:ring-2 focus:ring-primary-600 rounded-lg border-base-400 p-8 flex flex-col justify-center items-center `}
        rel="noopener noreferrer"
        href={url}
      >
        <div
          className={`mb-4 ${
            round
              ? `h-16 rounded-full p-4 bg-${round}-200 text-${round}-800`
              : 'dark:monochrome h-32'
          }`}
        >
          {icon}
        </div>
        <p className="text-2xl font-semibold">{title}</p>
      </a>
    )
  }

  return (
    <>
      <Seo title="StackRox Documentation" />
      <div className="overflow-hidden text-center py-12 md:py-16 lg:pb-24">
        <div className="flex flex-col items-center space-y-32 page-wrapper">
          <section className="flex flex-col items-center max-w-screen-lg z-0">
            <h1 className={headingStyle}>StackRox Documentation</h1>
            <p className={pStyle}>
              StackRox is the upstream open source project of{' '}
              <a
                className={aStyle}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.redhat.com/en/resources/advanced-cluster-security-for-kubernetes-datasheet"
              >
                Red Hat Advanced Cluster Security
              </a>
              . The StackRox project documentation is publicly available through{' '}
              <a
                className={aStyle}
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.openshift.com/acs/welcome/index.html"
              >
                OpenShift documentation
              </a>{' '}
              to select specific application&nbsp;versions.
            </p>
            <p className={pStyle}>
              We encourage your feedback by emailing{' '}
              <a className={aStyle} href="mailto:community@stackrox.io">
                community@stackrox.io
              </a>
              , or joining the monthly community meetings to discuss the future of the
              project&nbsp;documentation.
            </p>
          </section>
        </div>
      </div>
      <section className="bg-primary-100 py-8 md:py-16 flex flex-col w-full items-center text-center">
        <h2 className={headingStyle}>New to StackRox?</h2>
        <p className={ledeStyle}>Get started in your Kubernetes clusters&nbsp;quickly</p>
        <div className="page-wrapper mt-16 mb-12 gap-8 w-full flex flex-col md:flex-row justify-center">
          <IconBox
            title="Install with Helm (Recommended)"
            url="https://docs.openshift.com/acs/installing/installing_other/install-central-other.html#install-central-other"
            icon={<Helm alt="" />}
          />
          <IconBox
            title="Install with the roxctl CLI"
            url="https://docs.openshift.com/acs/cli/installing-the-roxctl-cli.html"
            icon={<StackRox alt="" />}
          />
          <IconBox
            title="Install with the OpenShift operator"
            url="https://docs.openshift.com/acs/installing/install-ocp-operator.html"
            icon={<Operator alt="" />}
          />
        </div>
      </section>
      <section className="page-wrapper py-8 md:py-16 flex flex-col w-full items-center text-center">
        <h2 className={headingStyle}>Learn More</h2>
        <div className="mt-16 mb-12 flex flex-wrap gap-12 justify-center">
          <IconBox
            title="Release Notes"
            url="https://docs.openshift.com/acs/release_notes"
            icon={<FileText alt="" />}
            round="accent"
          />
          <IconBox
            title="Architecture"
            url="https://docs.openshift.com/acs/architecture/acs-architecture.html"
            icon={<Home alt="" />}
            round="primary"
          />
          <IconBox
            title="Configuration"
            url="https://docs.openshift.com/acs/configuration/add-custom-certificates.html"
            icon={<Settings alt="" />}
            round="alert"
          />
          <IconBox
            title="Operation"
            url="https://docs.openshift.com/acs/operating/view-dashboard.html"
            icon={<Activity alt="" />}
            round="intermediate"
          />
          <IconBox
            title="Integrations"
            url="https://docs.openshift.com/acs/integration/integrate-with-image-registries.html"
            icon={<Minimize2 alt="" />}
            round="success"
          />
          <IconBox
            title="Backup & Restore"
            url="https://docs.openshift.com/acs/backup_and_restore/backing-up-acs.html"
            icon={<Database alt="" />}
            round="tertiary"
          />
          <IconBox
            title="Upgrading"
            url="https://docs.openshift.com/acs/upgrading/upgrade-helm.html"
            icon={<TrendingUp alt="" />}
            round="secondary"
          />
          <IconBox
            title="roxctl CLI"
            url="https://docs.openshift.com/acs/cli/installing-the-roxctl-cli.html"
            icon={<Terminal alt="" />}
            round="warning"
          />
        </div>
      </section>
    </>
  )
}
